// eslint-disable-next-line import/prefer-default-export
export const SETTIME = {
  GET_ALL: id => `/settimes/competition/${id}`,
  UPDATE: id => `/settimes/${id}`,
  UPDATE_ALL: id => `/settimes/all/${id}`,
  DELETE: id => `/settimes/${id}`,
  DELETE_ALL: id => `/settimes/all/${id}`,
  ADD: '/settimes',
  GET_REPORT: id => `/${id}`,
}
