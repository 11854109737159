export default [
  {
    path: '/template/template-habit',
    name: 'template-habit-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/templates-activity/activity-list/TemplateActivityList.vue'),
    meta: {
      pageTitle: 'Template',
      breadcrumb: [
        {
          text: 'Habit List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/template-habit/:id',
    name: 'template-habit-list-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/templates-activity/activity-list/TemplateActivityList.vue'),
    meta: {
      pageTitle: 'Template',
      breadcrumb: [
        {
          text: 'Templates List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/habit-configuration/:id',
    name: 'contents-habit-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/templates-activity/activity-configuration/HabitConfiguration.vue'),
    meta: {
      pageTitle: 'Template',
      breadcrumb: [
        {
          text: 'Habit Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/behaviour-configuration/:id',
    name: 'contents-behaviour-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/templates-activity/activity-configuration/BehaviourConfiguration.vue'),
    meta: {
      pageTitle: 'Template',
      breadcrumb: [
        {
          text: 'Behaviour Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/template',
    name: 'template-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/templates-admin/TemplateList.vue'),
    meta: {
      pageTitle: 'Template',
      breadcrumb: [
        {
          text: 'Templates List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/template-kpi',
    name: 'template-kpi-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/templates-kpi/kpi-list/TemplateKpiList.vue'),
    meta: {
      pageTitle: 'Template',
      breadcrumb: [
        {
          text: 'KPI List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/template-goals',
    name: 'template-goals-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/templates-goals/goals-list/TemplateGoalsList.vue'),
    meta: {
      pageTitle: 'Template',
      breadcrumb: [
        {
          text: 'Goals List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/template-charity',
    name: 'template-charity-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/templates-charity/charity-list/TemplateCharityList.vue'),
    meta: {
      pageTitle: 'Template',
      breadcrumb: [
        {
          text: 'Charity List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/kpi-configuration',
    name: 'template-kpi-configuration',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/templates-kpi/kpi-configuration/TemplateKpiConfiguration.vue'),
    meta: {
      pageTitle: 'Template',
      breadcrumb: [
        {
          text: 'KPI Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/template/kpi-configuration/:id',
    name: 'template-kpi-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/templates-kpi/kpi-configuration/TemplateKpiConfiguration.vue'),
    meta: {
      pageTitle: 'Template',
      breadcrumb: [
        {
          text: 'KPI Configuration',
          active: true,
        },
      ],
    },
  },
]
