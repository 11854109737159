/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { SURVEY } from '@core/services/survey'

/**
 * State
 */
const state = {
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  isUploading: false,
  allQuestions: [],
  questions: [],
  questionCount: 0,
  allSurveys: [],
  allSurveySets: [],
  surveyCount: 0,
  question: '',
  survey: '',
}
/**
 * getters
 */
const getters = {
  isLoading: state => state.isLoading,
  isCreating: state => state.isCreating,
  isDeleting: state => state.isDeleting,
  isUploading: state => state.isUploading,
  getAllQuestions: state => state.allQuestions,
  getQuestions: state => state.questions,
  getTotalQuestions: state => state.questionCount,
  getAllSurveys: state => state.allSurveys,
  getAllSurveySets: state => state.allSurveySets,
  getTotalSurveys: state => state.surveyCount,
  getQuestion: state => state.question,
  getSurvey: state => state.survey,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
  SET_IS_UPLOADING: (state, data) => {
    state.isUploading = data
  },
  SET_ALL_QUESTIONS: (state, data) => {
    state.allQuestions = data
  },
  SET_QUESTIONS: (state, data) => {
    state.questions = data
  },
  SET_ALL_QUESTION_COUNT: (state, data) => {
    state.questionCount = data
  },
  SET_ALL_SURVEYS: (state, data) => {
    state.allSurveys = data
  },
  SET_ALL_SURVEY_COUNT: (state, data) => {
    state.surveyCount = data
  },
  SET_QUESTION: (state, data) => {
    state.question = data
  },
  SET_SURVEY: (state, data) => {
    state.survey = data
  },
  SET_ALL_SURVEY_SETS: (state, data) => {
    state.allSurveySets = data
  },
}
/**
 * actions
 */
const actions = {

  /**
   * get all  Surveys
   * @returns {Promise<unknown>}
   */
  async fetchAllSurveys({ commit }, data) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(SURVEY.GET_SURVEY_ALL(data.page))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_SURVEYS', response.data.data.result)
          commit('SET_ALL_SURVEY_COUNT', response.data.data.count)
          resolve(response.data.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Survey
   * @returns {Promise<unknown>}
   */
  async createSurvey({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(SURVEY.SURVEY, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Survey Question
   * @returns {Promise<unknown>}
   */
  async createSurveyQuestion({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(SURVEY.SURVEY_QUESTION, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Update Survey Question
   * @returns {Promise<unknown>}
   */
  async updateSurveyQuestion({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(SURVEY.SURVEY_QUESTION_BY_ID(data.ID), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Survey User
   * @returns {Promise<unknown>}
   */
  async createSurveyUser({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(SURVEY.SURVEY_USER, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Create Survey Analyze
   * @returns {Promise<unknown>}
   */
  async createSurveyAnalyze({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(SURVEY.SURVEY_ANALYZE_BY_ID(data.ID))
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Update Survey
   * @returns {Promise<unknown>}
   */
  async updateSurvey({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(SURVEY.SURVEY_BY_ID(data.ID), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete  Survey
   * @returns {Promise<unknown>}
   */
  async deleteSurvey({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(SURVEY.SURVEY_BY_ID(data.ID))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete   Question Answer
   * @returns {Promise<unknown>}
   */
  async deleteQuestionAnswer({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(SURVEY.QUESTION_ANSWER_BY_ID(data.ID))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete  Survey Question
   * @returns {Promise<unknown>}
   */
  async deleteSurveyQuestion({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(SURVEY.SURVEY_QUESTION_BY_ID(data.ID))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete  Survey User
   * @returns {Promise<unknown>}
   */
  async deleteSurveyUser({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(SURVEY.SURVEY_USER_BY_ID(data.ID))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * get all  Questions
   * @returns {Promise<unknown>}
   */
  async fetchAllQuestions({ commit }, params) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(SURVEY.GET_ALL_QUESTIONS(params))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_QUESTIONS', response.data.data.result)
          commit('SET_ALL_QUESTIONS', response.data.data.allResult)
          commit('SET_ALL_QUESTION_COUNT', response.data.data.count)
          resolve(response.data.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Survey by ID
   * @returns {Promise<unknown>}
   */
  async fetchSurveyByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(SURVEY.SURVEY_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_SURVEY', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Survey Reults by ID
   * @returns {Promise<unknown>}
   */
  async fetchSurveyResultByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(SURVEY.SURVEY_RESULT_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_SURVEY', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Survey Set by ID
   * @returns {Promise<unknown>}
   */
  async fetchSurveySetByID({ commit }, params) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(SURVEY.SURVEY_SET_BY_ID(params))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_SURVEY_SETS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Question
   * @returns {Promise<unknown>}
   */
  async createQuestion({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(SURVEY.QUESTION, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Update Question
   * @returns {Promise<unknown>}
   */
  async updateQuestion({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(SURVEY.QUESTION_BY_ID(data.questionID), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * get Question by ID
   * @returns {Promise<unknown>}
   */
  async fetchQuestionByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(SURVEY.QUESTION_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_QUESTION', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete  Question
   * @returns {Promise<unknown>}
   */
  async deleteQuestion({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(SURVEY.QUESTION_BY_ID(data.questionID))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * Sent Survey Invitation Email or SMS
   * @returns {Promise<unknown>}
   */
  async sendSurveyInvitation({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(SURVEY.SURVEY_INVITATIONS(data.id), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * upload Project Users To Survey
   * @returns {Promise<unknown>}
   */
  async uploadAllProjectUsersToSurvey({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(SURVEY.ALL_PROJECT_USERS_SURVEY(data))
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
