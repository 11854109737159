export default [
  {
    path: '/games/charity',
    name: 'charity-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/charity/charity-list/CharityList.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Charity List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/charity/:id',
    name: 'charity-list-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/charity/charity-list/CharityList.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Charity List',
          active: true,
        },
      ],
    },
  },
]
