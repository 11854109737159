export default [
  {
    path: '/games/activity',
    name: 'activity-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/activity/activity-list/ActivityList.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Habit List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/activity/:id',
    name: 'activity-list-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/activity/activity-list/ActivityList.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Habit List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/activity-configuration',
    name: 'activity-configuration',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/activity/activity-configuration/ActivityConfiguration.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Habit Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/activity-configuration/:id',
    name: 'activity-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/activity/activity-configuration/ActivityConfiguration.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Habit Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/subactivity-configuration/:id',
    name: 'subactivity-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/activity/activity-configuration/SubActivityConfiguration.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Behaviour Configuration',
          active: true,
        },
      ],
    },
  },
]
