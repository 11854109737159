// eslint-disable-next-line import/prefer-default-export
export const GAME = {
  GET_ALL: '/competition/all',
  ADD: '/competition/add',
  UPDATE: id => `/competition/${id}`,
  UPDATE_GAME: 'competition/update',
  ADD_GROUP: '/group/add',
  ADD_GROUP_USER: '/competition/add/existing/user',
  UPDATE_GROUP: '/group/update',
  DELETE: id => `/competitions/${id}`,
  GET_SINGLE: id => `/competition/${id}`,
  GET_COMPETITION_CHARITY: id => `/competitioncharity?comp_id=${id}`,
  GET_AVAILABLE_USERS: (id, isAll) => `/user/competition/notExisting/${id}/${isAll}`,
  GET_CHARITY: '/charity',
  DELETE_CHARITY_ID: id => `/competitioncharity/${id}`,
  DELETE_GROUP: '/group/deleteGroupCompetition',
  DELETE_GROUP_USER: '/group/deleteGroupUser',
  ADD_CHARITY: '/competitioncharity',
  COPY_TASK: '/competition/existing/task/copy',
  COPY_KPI: '/competition/copy/kpi',
  COPY_GROUP: '/group/copy',
  SEND_INVITATION_EMAIL: '/competition/send/start/email',
  GET_GAME_REPORT: params => `/report/settime?${params}`,
  GAME_ANALYZE_BY_ID: id => `/competitions/analyze/${id}/performedtasks`,
  GET_GROUP_RESULTS_BY_ID: (id, params) => `/competitions/${id}/groupresults${params}`,
}
