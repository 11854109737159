/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest, httpUpload } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { MEDIA } from '@core/services/media'

/**
 * State
 */
const state = {
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  isUploading: false,
  allMedias: [],
  allQuestions: [],
  media: '',
  mediaCount: 0,
}
/**
 * getters
 */
const getters = {
  isLoading: state => state.isLoading,
  isCreating: state => state.isCreating,
  isDeleting: state => state.isDeleting,
  isUploading: state => state.isUploading,
  getAllMedias: state => state.allMedias,
  getAllQuestions: state => state.allQuestions,
  getMedia: state => state.media,
  getTotalMedias: state => state.mediaCount,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
  SET_IS_UPLOADING: (state, data) => {
    state.isUploading = data
  },
  SET_ALL_MEDIAS: (state, data) => {
    state.allMedias = data
  },
  SET_ALL_QUESTIONS: (state, data) => {
    state.allQuestions = data
  },
  SET_MEDIA: (state, data) => {
    state.media = data
  },
  SET_ALL_MEDIA_COUNT: (state, data) => {
    state.mediaCount = data
  },
}
/**
 * actions
 */
const actions = {

  /**
   * get all  Medias
   * @returns {Promise<unknown>}
   */
  async fetchAllMedias({ commit }, data) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(MEDIA.GET_ALL(data.page))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_MEDIAS', response.data.result)
          commit('SET_ALL_MEDIA_COUNT', response.data.count)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * delete Media
   * @returns {Promise<unknown>}
   */
  async deleteMedia({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(MEDIA.DELETE(data.mediaID))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data.success) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Medias
   * @returns {Promise<unknown>}
   */
  async createMedias({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(MEDIA.ADD, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data.success) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Upload Medias
   * @returns {Promise<unknown>}
   */
  async uploadMedias({ commit }, data) {
    commit('SET_IS_UPLOADING', true)
    return await new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('companyID', data.companyID)
      formData.append('media', data.selectedFile)
      formData.append('header', data.header)
      formData.append('scope', data.scope)
      formData.append('lang', data.lang)
      formData.append('description', data.description)
      httpUpload()
        .post(MEDIA.UPLOAD, formData)
        .then(response => {
          commit('SET_IS_UPLOADING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_UPLOADING', false)
          reject(error)
        })
    })
  },
  /**
   * update Medias
   * @returns {Promise<unknown>}
   */
  async updateMedias({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(MEDIA.UPDATE(data.mediaID), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * get Medias by ID
   * @returns {Promise<unknown>}
   */
  async fetchMediaByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(MEDIA.GET_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_MEDIA', response.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Questions by MediaID
   * @returns {Promise<unknown>}
   */
  async fetchQuestionByMediaID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(MEDIA.GET_QUESTION_BY_MEDIA_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_QUESTIONS', response.data.data.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Question
   * @returns {Promise<unknown>}
   */
  async createQuestion({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(MEDIA.QUESTION, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete new Question
   * @returns {Promise<unknown>}
   */
  async deleteQuestion({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(MEDIA.QUESTION_BY_ID(data.questionID))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * update Question
   * @returns {Promise<unknown>}
   */
  async updateQuestion({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(MEDIA.QUESTION_BY_ID(data.questionID), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
