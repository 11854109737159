// eslint-disable-next-line import/prefer-default-export
export const USER = {
  USER_UPLOAD: '/user/upload',
  USER_GAME_UPLOAD: '/user/game/upload',
  USER_UPLOAD_CONFIRMATION: id => `/user/upload/confirmation/${id}`,
  GET_ALL: '/user/all',
  GET_BY_ID: id => `/users/${id}`,
  GET_BY_COMPANY_ID: id => `/users?companyID=${id}`,
  GET_USER_BATCH: '/userbatch',
  DELETE_USER_BATCH: id => `/userbatch/${id}`,
  DELETE_USER_UPLOAD: id => `/useruploads/${id}`,
  GET_USER_UPLOADS_BY_BATCH_ID: params => `/useruploads/pagination/${params.page}?batch_id=${params.id}${params.searchQuery}`,
  GET_GAME_UPLOADS_BY_BATCH_ID: params => `/useruploads?batch_id=${params.id}&group_by=${params.group_by}`,
  GET_USER_PERFORMED_TASKS_BY_ID: id => `/user/task/performedtasks/${id}?limit=5`,
  GET_GROUP_GAME_BY_ID: id => `/user/competition/group/${id}?limit=5`,
  UPDATE: id => `/users/${id}`,
  DELETE: id => `/users/${id}`,
  ADD: '/users',
  GET_USER_ROLE: '/user/role',
  SEND_EMAIL_USER: '/user/send/email',
  GET_USER_NOTIFICATIONS_BY_ID: id => `/usernotifications?user_id=${id}&record_status=1`,
  UPDATE_USER_NOTIFICATIONS_BY_ID: id => `/usernotifications/${id}`,
  FORGOT_PASSWORD: '/user/forgotpassword',
  RESET_PASSWORD: '/user/resetpassword',
  GET_RESET_PASSWORD: id => `/user/resetpassword?id=${id}`,
}
