/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { GOAL } from '@core/services/goal'

/**
 * State
 */
const state = {
  isUploading: false,
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  allGoals: [],
}
/**
 * getters
 */
const getters = {
  isCreating: state => state.isCreating,
  isDeleting: state => state.isDeleting,
  isLoading: state => state.isLoading,
  getAllGoals: state => state.allGoals,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_ALL_GOALS: (state, data) => {
    state.allGoals = data
  },
}
/**
 * actions
 */
const actions = {
  /**
   * get Goal by ID
   * @returns {Promise<unknown>}
   */
  async fetchGoalByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(GOAL.GET_GOALS_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_GOALS', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
