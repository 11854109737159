/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { TASK } from '@core/services/task'

/**
 * State
 */
const state = {
  isUploading: false,
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  gameTasks: [],
}
/**
 * getters
 */
const getters = {
  isCreating: state => state.isCreating,
  isDeleting: state => state.isDeleting,
  isLoading: state => state.isLoading,
  getGameTasks: state => state.gameTasks,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_GAME_TASKS: (state, data) => {
    state.gameTasks = data
  },
}
/**
 * actions
 */
const actions = {
  /**
   * get Game Tasks by ID
   * @returns {Promise<unknown>}
   */
  async fetchGameTaskByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(TASK.GET_GAME_TASKS_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_GAME_TASKS', response.data.result)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
