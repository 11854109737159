export default [
  {
    path: '/survey/question',
    name: 'question-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/question/question-list/QuestionList.vue'),
    meta: {
      pageTitle: 'Survey',
      breadcrumb: [
        {
          text: 'Question List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/question/question-configuration',
    name: 'question-configuration',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/question/question-configuration/QuestionConfiguration.vue'),
    meta: {
      pageTitle: 'Question',
      breadcrumb: [
        {
          text: 'Question Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/question/question-configuration/:id',
    name: 'question-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/question/question-configuration/QuestionConfiguration.vue'),
    meta: {
      pageTitle: 'Question',
      breadcrumb: [
        {
          text: 'Question Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/survey/list',
    name: 'survey-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/survey/survey-list/SurveyList.vue'),
    meta: {
      pageTitle: 'Survey',
      breadcrumb: [
        {
          text: 'Survey List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/usersurvey/list',
    name: 'usersurvey-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/survey/survey-list/UserSurveyList.vue'),
    meta: {
      pageTitle: 'Survey',
      breadcrumb: [
        {
          text: 'Survey List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/survey/survey-configuration',
    name: 'survey-configuration',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/survey/survey-configuration/SurveyConfiguration.vue'),
    meta: {
      pageTitle: 'Survey',
      breadcrumb: [
        {
          text: 'Survey Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/survey/survey-configuration/:id',
    name: 'survey-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/survey/survey-configuration/SurveyConfiguration.vue'),
    meta: {
      pageTitle: 'Survey',
      breadcrumb: [
        {
          text: 'Survey Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/survey/survey-question/:id',
    name: 'survey-question-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/survey/survey-configuration/SurveyQuestion.vue'),
    meta: {
      pageTitle: 'Survey',
      breadcrumb: [
        {
          text: 'Survey Question',
          active: true,
        },
      ],
    },
  },
  {
    path: '/survey/survey-view/:id',
    name: 'survey-view-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/survey/survey-configuration/SurveyView.vue'),
    meta: {
      pageTitle: 'Survey',
      breadcrumb: [
        {
          text: 'Survey View',
          active: true,
        },
      ],
    },
  },
]
