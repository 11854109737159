/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { httpRequest, tokenRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { ACTIVITY } from '@core/services/activity'

/**
 * State
 */
const state = {
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  allActivities: '',
  paginations: '',
  activity: '',
  subActivity: '',
}
/**
 * getters
 */
const getters = {
  isLoading: state => state.isLoading,
  isCreating: state => state.isCreating,
  isDeleting: state => state.isDeleting,
  getAllActivities: state => state.allActivities,
  getPagination: state => state.paginations,
  getActivity: state => state.activity,
  getSubActivity: state => state.subActivity,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_ALL_ACTIVITIES: (state, data) => {
    state.allActivities = data
  },
  SET_ACTIVITY: (state, data) => {
    state.activity = data
  },
  SET_SUB_ACTIVITY: (state, data) => {
    state.subActivity = data
  },
  SET_PAGINATIONS: (state, data) => {
    state.paginations = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
}

/**
 * actions
 */
const actions = {
  /**
   * Create new Activity
   * @returns {Promise<unknown>}
   */
  async createActivity({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(ACTIVITY.ADD, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Sub Activity
   * @returns {Promise<unknown>}
   */
  async createSubActivity({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(ACTIVITY.ADD_SUBTASK, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data.success) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * update Activity
   * @returns {Promise<unknown>}
   */
  async updateActivity({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(ACTIVITY.UPDATE, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * update Sub Activity
   * @returns {Promise<unknown>}
   */
  async updateSubActivity({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(ACTIVITY.UPDATE_SUBTASK, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * delete Activity
   * @returns {Promise<unknown>}
   */
  async deleteActivity({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(ACTIVITY.DELETE, data)
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data.success) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * delete Sub Activity
   * @returns {Promise<unknown>}
   */
  async deleteSubActivity({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(ACTIVITY.DELETE_SUBTASK, data)
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data.success) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Activities
   * @returns {Promise<unknown>}
   */
  async fetchAllActivities({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(ACTIVITY.GET_ALL(id, 1))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_ACTIVITIES', response.data.result)
          resolve(response.data.result)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Activity by ID
   * @returns {Promise<unknown>}
   */
  async fetchActivityByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(ACTIVITY.GET_TASK_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ACTIVITY', response.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Sub Activity by ID
   * @returns {Promise<unknown>}
   */
  async fetchSubActivityByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(ACTIVITY.GET_SUBTASK_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_SUB_ACTIVITY', response.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Activity
   * @returns {Promise<unknown>}
   */
  async fetchActivity({ commit }, data) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      httpRequest()
        .post(ACTIVITY.CREATE(data))
        .then(response => {
          commit('SET_IS_LOADING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
