export default [
  {
    path: '/games/media',
    name: 'media-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/media/media-list/MediaList.vue'),
    meta: {
      pageTitle: 'Media',
      breadcrumb: [
        {
          text: 'Media List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/media/:id',
    name: 'media-list-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/media/media-activity/MediaActivity.vue'),
    meta: {
      pageTitle: 'Media',
      breadcrumb: [
        {
          text: 'Habit & Behaviour',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/media-configuration',
    name: 'media-configuration',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/media/media-configuration/MediaConfiguration.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Media Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/media-configuration/:id',
    name: 'media-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/media/media-configuration/MediaConfiguration.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Media Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/quiz-configuration/:id',
    name: 'quiz-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/media/media-configuration/QuizConfiguration.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'Quiz Edit',
          active: true,
        },
      ],
    },
  },
]
