// eslint-disable-next-line import/prefer-default-export
export const TEMPLATE = {
  GET_TEMPLATE_CATEGORIES_ALL: '/game/templates-category/all',
  GET_TEMPLATES_ALL: params => `/templates${params}`,
  GET_TEMPLATES_TASK: id => `/game/templates-task/all?template_id=${id}`,
  DELETE_TEMPLATE_TASK: id => `/templates-task/${id}`,
  DELETE_TEMPLATE_SUB_TASK: id => `/templates-sub-task/${id}`,
  ADD_TASK: '/templates-task',
  UPDATE_TASK: id => `/templates-task/${id}`,
  ADD_SUB_TASK: '/templates-sub-task',
  UPDATE_SUB_TASK: id => `/templates-sub-task/${id}`,
  GET_TASK_BY_ID: id => `/templates-task/${id}`,
  GET_SUBTASK_BY_ID: id => `/templates-sub-task/${id}`,
  ADD_TEMPLATE_BY_GAME: id => `/competitions/${id}/create-templates`,
  UPDATE_TEMPLATE: id => `/templates/${id}`,
  GET_TEMPLATES_CHARITY_ALL: id => `templatecharity?template_id=${id}`,
  ADD_TEMPLATE_CHARITY: '/templatecharity',
  UPDATE_TEMPLATE_CHARITY: id => `/templatecharity/${id}`,
  ADD_TEMPLATE_KPI: '/templatekpis',
  TEMPLATE_KPI_ID: id => `/templatekpis/${id}`,
  GET_TEMPLATE_KPI_ALL: id => `templatekpis?template_id=${id}`,
  ADD_TEMPLATE_TARGET_KPI: '/templatetargetkpi',
  UPDATE_TEMPLATE_TARGET_KPI: id => `/templatetargetkpi/${id}`,
  RESET_TEMPLATE_TARGET_KPI: id => `/templatetargetkpi/templates/${id}`,
  GET_TEMPLATE_TARGET_KPI_ALL: id => `templatetargetkpi?template_id=${id}`,
  ADD_TEMPLATE_BY_SURVEY: id => `/surveys/${id}/create-templates`,
}
