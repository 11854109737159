// eslint-disable-next-line import/prefer-default-export
export const ACTIVITY = {
  GET_ALL: (competitionId, page) => `/task/competition/${competitionId}/${page}`,
  ADD: '/task/competition/subtask/add',
  ADD_SUBTASK: '/task/add/subtask/task',
  UPDATE_SUBTASK: '/task/update/sub',
  UPDATE: 'task/update/main',
  DELETE: '/task/competition/delete',
  DELETE_SUBTASK: '/task/subtask/delete',
  GET_TASK_BY_ID: id => `/task/${id}`,
  GET_SUBTASK_BY_ID: id => `/subtask/${id}`,
}
