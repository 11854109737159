// eslint-disable-next-line import/prefer-default-export
export const PROJECT = {
  PROJECT: '/projects',
  GET_PROJECT_ALL: page => `/projects/pagination/${page}`,
  PROJECT_BY_ID: id => `/projects/${id}`,
  PROJECT_USER: '/projectusers',
  PROJECT_USER_BY_ID: id => `/projectusers/${id}`,
  PROJECT_BY_COMPANY_ID: id => `/projects?company_id=${id}`,
  PROJECT_INVITATIONS: id => `/projects/invitations/send/${id}`,
  ALL_COMPANY_USERS_PROJECTS: params => `/projects/${params.id}/companies/${params.company_id}/add-all-users`,
}
