export default [
  {
    path: '/games/kpi',
    name: 'kpi-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/kpi/kpi-list/KpiList.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'KPI List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/kpi/:id',
    name: 'kpi-list-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/kpi/kpi-list/KpiList.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'KPI List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/kpi-configuration',
    name: 'kpi-configuration',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/kpi/kpi-configuration/KpiConfiguration.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'KPI Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/games/kpi-configuration/:id',
    name: 'kpi-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/kpi/kpi-configuration/KpiConfiguration.vue'),
    meta: {
      pageTitle: 'Game',
      breadcrumb: [
        {
          text: 'KPI Configuration',
          active: true,
        },
      ],
    },
  },
]
