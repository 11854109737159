/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest, keyRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { TEMPLATE } from '@core/services/template'

/**
 * State
 */
const state = {
  isLoading: false,
  allTemplates: {
    data: [],
    paginator: {
      count: 0,
      pages: 0,
    },
  },
  allTemplateKpi: {
    data: [],
    paginator: {
      count: 0,
      pages: 0,
    },
  },
  allTemplateTargetKpi: {
    data: [],
    paginator: {
      count: 0,
      pages: 0,
    },
  },
  allTemplateTasks: [],
  allCategories: [],
  allTemplateCharity: [],
  isCreating: false,
  isDeleting: false,
  task: '',
  subTask: '',
  pagination: {},
}
/**
 * getters
 */
const getters = {
  getAllTemplates: state => state.allTemplates,
  getAllTemplateKpi: state => state.allTemplateKpi,
  getAllTemplateTargetKpi: state => state.allTemplateTargetKpi,
  getAllTemplateTasks: state => state.allTemplateTasks,
  getAllTemplateCharity: state => state.allTemplateCharity,
  getAllCategories: state => state.allCategories,
  isLoading: state => state.isLoading,
  isCreating: state => state.isCreating,
  isDeleting: state => state.isDeleting,
  getTask: state => state.task,
  getSubTask: state => state.subTask,
  getPagination: state => state.pagination,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_ALL_TEMPLATES: (state, data) => {
    state.allTemplates = data
  },
  SET_ALL_TEMPLATE_KPI: (state, data) => {
    state.allTemplateKpi = data
  },
  SET_ALL_TEMPLATE_TARGET_KPI: (state, data) => {
    state.allTemplateTargetKpi = data
  },
  SET_ALL_TEMPLATE_TASK: (state, data) => {
    state.allTemplateTasks = data
  },
  SET_ALL_TEMPLATE_CHARITY: (state, data) => {
    state.allTemplateCharity = data
  },
  SET_ALL_CATEGORIES: (state, data) => {
    state.allCategories = data
  },
  SET_IS_DELETING: (state, data) => {
    state.isDeleting = data
  },
  SET_TASK: (state, data) => {
    state.task = data
  },
  SET_SUB_TASK: (state, data) => {
    state.subTask = data
  },
}
/**
 * actions
 */
const actions = {
  /**
   * Create Template KPI
   * @returns {Promise<unknown>}
   */
  async createTemplateKpi({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(TEMPLATE.ADD_TEMPLATE_KPI, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Create Template target  KPI
   * @returns {Promise<unknown>}
   */
  async createTemplateTargetKpi({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(TEMPLATE.ADD_TEMPLATE_TARGET_KPI, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Update Template target KPI
   * @returns {Promise<unknown>}
   */
  async updateTemplateTargetKpi({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(TEMPLATE.UPDATE_TEMPLATE_TARGET_KPI(data.id), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Update Template KPI
   * @returns {Promise<unknown>}
   */
  async updateTemplateKpi({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(TEMPLATE.TEMPLATE_KPI_ID(data.ID), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Fetch Template KPI by ID
   * @returns {Promise<unknown>}
   */
  async fetchTemplateKpiByID({ commit }, id) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(TEMPLATE.TEMPLATE_KPI_ID(id))
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Update Template
   * @returns {Promise<unknown>}
   */
  async updateTemplate({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(TEMPLATE.UPDATE_TEMPLATE(data.ID), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete Template target KPI
   * @returns {Promise<unknown>}
   */
  async deleteTemplateTargetKpi({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(TEMPLATE.UPDATE_TEMPLATE_TARGET_KPI(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete Template
   * @returns {Promise<unknown>}
   */
  async deleteTemplate({ commit }, data) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(TEMPLATE.UPDATE_TEMPLATE(data.ID), data)
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * Delete Template
   * @returns {Promise<unknown>}
   */
  async deleteTemplateKpi({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(TEMPLATE.TEMPLATE_KPI_ID(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * Create new Task
   * @returns {Promise<unknown>}
   */
  async createTask({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(TEMPLATE.ADD_TASK, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
     * create Template Charity
     * @returns {Promise<unknown>}
     */
  async createTemplateCharity({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(TEMPLATE.ADD_TEMPLATE_CHARITY, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
     * Create new Sub Task
     * @returns {Promise<unknown>}
     */
  async createSubTask({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(TEMPLATE.ADD_SUB_TASK, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
     * update Task
     * @returns {Promise<unknown>}
     */
  async updateTask({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(TEMPLATE.UPDATE_TASK(data.taskID), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
     * update Sub Task
     * @returns {Promise<unknown>}
     */
  async updateSubTask({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .put(TEMPLATE.UPDATE_SUB_TASK(data.subtaskID), data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * get Task by ID
   * @returns {Promise<unknown>}
   */
  async fetchTaskByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(TEMPLATE.GET_TASK_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_TASK', response.data.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Sub Task by ID
   * @returns {Promise<unknown>}
   */
  async fetchSubTaskByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(TEMPLATE.GET_SUBTASK_BY_ID(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_SUB_TASK', response.data.data)
          resolve(response)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * delete Task
   * @returns {Promise<unknown>}
   */
  async deleteTask({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(TEMPLATE.DELETE_TEMPLATE_TASK(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * delete Template Charity
   * @returns {Promise<unknown>}
   */
  async deleteTemplateCharity({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(TEMPLATE.UPDATE_TEMPLATE_CHARITY(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * delete Sub Task
   * @returns {Promise<unknown>}
   */
  async deleteSubTask({ commit }, id) {
    commit('SET_IS_DELETING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .delete(TEMPLATE.DELETE_TEMPLATE_SUB_TASK(id))
        .then(response => {
          commit('SET_IS_DELETING', false)
          if (response) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_DELETING', false)
          reject(error)
        })
    })
  },
  /**
   * get all  Categories
   * @returns {Promise<unknown>}
   */
  async fetchAllCategories({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .get(TEMPLATE.GET_TEMPLATE_CATEGORIES_ALL)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_CATEGORIES', response.data.data.allResult)
          resolve(response.data.data.allResult)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Templates
   * @returns {Promise<unknown>}
   */
  async fetchAllTemplates({ commit }, params) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(TEMPLATE.GET_TEMPLATES_ALL(params))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_TEMPLATES', {
            data: response.data.data.allResult,
            paginator: {
              count: response.data.data.count,
              pages: response.data.data.pages,
            },
          })
          resolve(response.data.data.allResult)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Templates
   * @returns {Promise<unknown>}
   */
  async fetchAllTemplateKpi({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(TEMPLATE.GET_TEMPLATE_KPI_ALL(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_TEMPLATE_KPI', {
            data: response.data.data.allResult,
            paginator: {
              count: response.data.data.count,
              pages: response.data.data.pages,
            },
          })
          resolve(response.data.data.allResult)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all target KPI Templates
   * @returns {Promise<unknown>}
   */
  async fetchAllTemplateTargetKpi({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(TEMPLATE.GET_TEMPLATE_TARGET_KPI_ALL(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_TEMPLATE_TARGET_KPI', {
            data: response.data.data.allResult,
            paginator: {
              count: response.data.data.count,
              pages: response.data.data.pages,
            },
          })
          resolve(response.data.data.allResult)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Template Task
   * @returns {Promise<unknown>}
   */
  async fetchAllTemplateTask({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .get(TEMPLATE.GET_TEMPLATES_TASK(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_TEMPLATE_TASK', response.data.data.allResult)
          resolve(response.data.data.allResult)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Template Charity
   * @returns {Promise<unknown>}
   */
  async fetchAllTemplateCharity({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(TEMPLATE.GET_TEMPLATES_CHARITY_ALL(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_TEMPLATE_CHARITY', response.data.data.allResult)
          resolve(response.data.data.allResult)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
     * create Template By Game
     * @returns {Promise<unknown>}
     */
  async createTemplateByGame({ commit }, payload) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .post(TEMPLATE.ADD_TEMPLATE_BY_GAME(payload.key), payload.data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response) {
            resolve(response.data.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
