/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { keyRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { ONBOARDING } from '@core/services/onboarding'

/**
 * State
 */
const state = {
  isCreating: false,
  isLoading: false,
  user: '',
  company: '',
  game: '',
  survey: '',
  allGame: {},
  allTemplates: [],
  allPerformedTasks: [],
  allGameSet: [],
  allGameGroup: [],
  allGameSetReult: [],
  allGameSetReults: [],
  allTargetsKpis: [],
}
/**
 * getters
 */
const getters = {
  isCreating: state => state.isCreating,
  isLoading: state => state.isLoading,
  getUser: state => state.user,
  getCompany: state => state.company,
  getGame: state => state.game,
  getAllGame: state => state.allGame,
  getAllPerformedTasks: state => state.allPerformedTasks,
  getAllGameSet: state => state.allGameSet,
  getAllGameGroup: state => state.allGameGroup,
  getAllGameSetReult: state => state.allGameSetReult,
  getAllGameSetReults: state => state.allGameSetReults,
  getAllTargetsKpis: state => state.allTargetsKpis,
  getSurvey: state => state.survey,
  getAllTemplates: state => state.allTemplates,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_CREATING: (state, data) => {
    state.isCreating = data
  },
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_USER: (state, data) => {
    state.user = data
  },
  SET_COMPANY: (state, data) => {
    state.company = data
  },
  SET_GAME: (state, data) => {
    state.game = data
  },
  SET_ALL_GAME: (state, data) => {
    state.allGame = data
  },
  SET_ALL_PERFORMED_TASK: (state, data) => {
    state.allPerformedTasks = data
  },
  SET_ALL_GAME_SET: (state, data) => {
    state.allGameSet = data
  },
  SET_ALL_GAME_GROUP: (state, data) => {
    state.allGameGroup = data
  },
  SET_ALL_GAME_SET_RESULT: (state, data) => {
    state.allGameSetReult = data
  },
  SET_ALL_GAME_SET_RESULTS: (state, data) => {
    state.allGameSetReults = data
  },
  SET_ALL_TARGET_KPIS: (state, data) => {
    state.allTargetsKpis = data
  },
  SET_SURVEY: (state, data) => {
    state.survey = data
  },
  SET_ALL_TEMPLATES: (state, data) => {
    state.allTemplates = data
  },
}

/**
 * actions
 */
const actions = {
  /**
   * Create new Onboarding
   * @returns {Promise<unknown>}
   */
  async createOnboarding({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .post(ONBOARDING.ADD, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response) {
            resolve(response.data.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Create Onboarding User
   * @returns {Promise<unknown>}
   */
  async createOnboardingUser({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .post(ONBOARDING.ADD_ONBOARDING_USER, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * Create Sales Leads
   * @returns {Promise<unknown>}
   */
  async createSalesLeads({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .post(ONBOARDING.ADD_SALES_LEADS, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Game
   * @returns {Promise<unknown>}
   */
  async fetchAllGame({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .get(ONBOARDING.GET_ALL_GAME(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_GAME', response.data.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get User
   * @returns {Promise<unknown>}
   */
  async fetchUserByEmail({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .get(ONBOARDING.GET_USER(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_USER', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Company
   * @returns {Promise<unknown>}
   */
  async fetchCompany({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .get(ONBOARDING.GET_COMPANY(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_COMPANY', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Game
   * @returns {Promise<unknown>}
   */
  async fetchGame({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .get(ONBOARDING.GET_GAME(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_GAME', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Target KPI ID
   * @returns {Promise<unknown>}
   */
  async fetchTargetKPIByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .get(ONBOARDING.GET_TARGETS_KPIS(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_TARGET_KPIS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Game PerformedTasks ID
   * @returns {Promise<unknown>}
   */
  async fetchPerformedTasksByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .get(ONBOARDING.GET_PERFORMED_TASKS(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_PERFORMED_TASK', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Game Set ID
   * @returns {Promise<unknown>}
   */
  async fetchGameSetByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .get(ONBOARDING.GET_GAME_SET(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_GAME_SET', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Performed Group ID
   * @returns {Promise<unknown>}
   */
  async fetchPerformedGroup({ commit }, params) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .get(ONBOARDING.GET_PERFORMED_GROUP(params))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_GAME_GROUP', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * create User Unsubscribe
   * @returns {Promise<unknown>}
   */
  async createUserUnsubscribe({ commit }, params) {
    console.log(params)
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .post(ONBOARDING.ADD_UNSUBSCRIBE_EMAIL(params))
        .then(response => {
          commit('SET_IS_LOADING', false)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get SetGame Result By ID
   * @returns {Promise<unknown>}
   */
  async fetchSetGameResultByID({ commit }, params) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .get(ONBOARDING.GET_SET_TIME_RESULT(params))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_GAME_SET_RESULT', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get SetGame Results
   * @returns {Promise<unknown>}
   */
  async fetchSetGameResults({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .get(ONBOARDING.GET_SET_TIME_RESULTS(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_GAME_SET_RESULTS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * get Survey by ID
   * @returns {Promise<unknown>}
   */
  async fetchSurveyByID({ commit }, params) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .get(ONBOARDING.SURVEY_BY_ID(params))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_SURVEY', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
  /**
   * Create Survey
   * @returns {Promise<unknown>}
   */
  async createSurvey({ commit }, data) {
    commit('SET_IS_CREATING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .post(ONBOARDING.SURVEY_QUESTION_ANSWERS, data)
        .then(response => {
          commit('SET_IS_CREATING', false)
          if (response.data) {
            resolve(response.data)
          }
        })
        .catch(error => {
          commit('SET_IS_CREATING', false)
          reject(error)
        })
    })
  },
  /**
   * get all Templates
   * @returns {Promise<unknown>}
   */
  async fetchTemplatesByID({ commit }, id) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      keyRequest()
        .get(ONBOARDING.GET_ALL_TEMPLATE(id))
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_TEMPLATES', {
            data: response.data.data.allResult,
            paginator: {
              count: response.data.data.count,
              pages: response.data.data.pages,
            },
          })
          resolve(response.data.data.allResult)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
