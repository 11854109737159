/* eslint-disable no-return-await */
/**
 * @module:Vuex Boiler Plate
 * @name:_Rohan wijesunda
 * @author:_@bisevo
 */
// eslint-disable-next-line import/extensions, import/no-unresolved
import { tokenRequest } from '@core/auth/interceptors/inteceptors'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { COMPANY } from '@core/services/company'

/**
 * State
 */
const state = {
  isLoading: false,
  allCompanies: [],
}
/**
 * getters
 */
const getters = {
  getAllCompanies: state => state.allCompanies,
  isLoading: state => state.isLoading,
}
/**
 * mutations
 */
const mutations = {
  SET_IS_LOADING: (state, data) => {
    state.isLoading = data
  },
  SET_ALL_COMPANIES: (state, data) => {
    state.allCompanies = data
  },
}
/**
 * actions
 */
const actions = {

  /**
   * get all  Companies
   * @returns {Promise<unknown>}
   */
  async fetchAllCompanies({ commit }) {
    commit('SET_IS_LOADING', true)
    return await new Promise((resolve, reject) => {
      tokenRequest()
        .get(COMPANY.GET_ALL)
        .then(response => {
          commit('SET_IS_LOADING', false)
          commit('SET_ALL_COMPANIES', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_IS_LOADING', false)
          reject(error)
        })
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
