// eslint-disable-next-line import/prefer-default-export
export const MEDIA = {
  GET_ALL: page => `/media/all/pagination/${page}`,
  DELETE: id => `/media/${id}`,
  ADD: '/media',
  UPLOAD: '/media/upload',
  QUESTION: '/questions',
  QUESTION_BY_ID: id => `/questions/${id}`,
  GET_BY_ID: id => `/media/${id}`,
  GET_QUESTION_BY_MEDIA_ID: id => `/questions?mediaID=${id}`,
}
